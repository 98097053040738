import React from 'react';
import ReactDOM from 'react-dom';
import { datadogRum } from '@datadog/browser-rum';
import * as FullStory from '@fullstory/browser';
import { shouldCaptureSessionFS } from 'src/fullstory';
import getComponentName from '../utils/datadogRouterMap';

if (typeof window.React !== 'object') {
  window.React = React;
  window.ReactDOM = ReactDOM;
}

export default () => {
  if (window.Glide.FULLSTORY_ORG_ID) {
    FullStory.init({ orgId: window.Glide.FULLSTORY_ORG_ID });
    if (shouldCaptureSessionFS(window.Glide?.user?.selectedRoles)) {
      const sessionUrl = FullStory.getCurrentSessionURL(true);
      if (sessionUrl) {
        datadogRum.addRumGlobalContext('fullstory', { sessionUrl });
      }
      if (window.Glide?.user?.id) {
        FullStory.identify(window.Glide.user.id);
      }
    } else {
      FullStory.shutdown();
    }
  }
  if (
    window.Glide.DATADOG_APPLICATION_ID &&
    window.Glide.DATADOG_CLIENT_TOKEN &&
    window.Glide.DATADOG_SITE
  ) {
    datadogRum.init({
      applicationId: window.Glide.DATADOG_APPLICATION_ID,
      clientToken: window.Glide.DATADOG_CLIENT_TOKEN,
      site: window.Glide.DATADOG_SITE,
      service: 'glide-webapp',
      env: window.Glide.env,
      version: window.Glide.RELEASE,
      sessionSampleRate: 50,
      trackUserInteractions: true,
      sessionReplaySampleRate: 0,
      beforeSend: (event) => {
        event.context = {
          ...event.context,
          component: getComponentName(window.router?.getState()),
        };
      },
    });
  }
};
