export function getQueryParam(qparam: string, remove = false) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const res = urlSearchParams.get(qparam);
  if (res && remove) {
    urlSearchParams.delete(qparam);
    const newQueryParams = urlSearchParams.toString() || '';
    const url =
      window.location.href.split('?')[0] +
      (newQueryParams ? '?' : '') +
      newQueryParams;
    window.history.pushState({}, '', url);
  }
  return res;
}
